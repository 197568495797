import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentSlider from "../../components/GSTTemp/ContentSlider";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function PvtToPublicCompany() {
  /* Slider */
  // const ConPvtToPubSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Conversion of Pvt Ltd and a Public Limited Company`,
  //     content: `Online Conversion of Pvt Ltd and a Public Limited Company Service with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/iec/iec-reg.png",
  //     alt_tag: "Conversion of Pvt Ltd and a Public Limited Company in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Conversion Of Pvt to Public Ltd`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const ConPvtToPubAboutData = {
    header: `Conversion of Pvt Ltd and a Public Limited Company`,
    sub_title: `For conversion of Pvt Ltd and a public limited company with TODAYFILINGS Experts.`,
    content_paragraph: [
      `The Indian Companies Act of 2013 governs the registration of
        private limited companies. A private corporation must have
        at least 2 shareholders in accordance with the Companies Act
        of 2013. One of the highly recommended enterprises in India,
        particularly for startups, is a private limited company.`,

      // `One variety of limited corporation is the public limited
      //   company. Compared to a Private Limited Company, it is
      //   subject to stricter regulatory obligations. A Public Limited
      //   Company must have three Directors or more in order to be
      //   formed. The maximum number of members a business may have is
      //   unrestricted.`,
    ],
  };

  /* Scroll Nav Data */
  const ConPvtToPubScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#conversion`,
      heading: `Conversion`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const ConPvtToPubIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: `Procedure for Changing a private limited corporation
      to a public limited company`,
    image: '/imgs/registration/iec/iec.png',
    alt_tag: `Changing a private limited corporation
      to a public limited company`,
    Paragraph: `According to the relevant provisos of the Companies
      Act of 2013 and the Companies (Incorporation) Rules
      of 2014, the following procedure must be followed in
      order to convert a private limited company into a
      public limited company:`,
    points: [
      {
        head: `Board Meeting`,
        content: '',
        icon: false,
      },
      {
        content: `Adoption of a new and updated MOA with the consent
          of the shareholders or members.`,
        icon: true,
      },
      {
        content: `Becoming a public limited company from a private
          limited corporation.`,
        icon: true,
      },
      {
        content: `Getting permission to hold an extraordinary general
          meeting and then giving someone the go-ahead to
          distribute the notice of the EGM.`,
        icon: true,
      },
      {
        content: `Additionally, the location, timing, and date of the
          extraordinary general meeting must be determined.`,
        icon: true,
      },
      {
        head: `Issuance of an EGM notice and announcement of the EGM`,
        content: `Following the conclusion of the Board Meeting, the
          Director and Company Secretary designated to
          distribute the notice of the extraordinary general
          meeting may do so to all of the following:`,
        icon: true,
      },
      {
        content: `Shareholders`,
        icon: true,
      },
      {
        content: `Directors`,
        icon: true,
      },
      {
        content: `Auditors`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ConPvtToPubCfData = {
    id: 'conversion',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Requirements Following Conversion',
    content: `The company must apply for a new PAN card.and
      company's bank account information must be updated.`,
    body: [
      {
        points: `All business letterheads and stationery should be
          changed with the new company name.`,
        icon: true,
      },
      {
        points: `The tax authorities, as well as all other relevant
          staff, must be informed of the conversion into a
          public limited company.`,
        icon: true,
      },
      {
        points: `Printed copies of the freshly drafted MOA and AOA must
          be prepared as soon as possible.`,
        icon: true,
      },
      {
        points: `Another advantage of conversion is that the company
          will be listed on the stock exchange in addition to
          being converted. As it enables the company to raise
          additional funds while also allowing them to scale
          their business functions more easily.`,
        icon: true,
      },
      {
        head: `Filing of the form with RoC`,
        points: `Form filing formality with the Registrar of Companies
          must be completed within the allotted time period
          after the company's resolutions are approved at the
          extraordinary general meeting, or EGM.`,
        icon: true,
      },
      {
        head: `E-Form MGT – 14`,
        points: `This E-Form MGT-14 form, along with the required fees,
          must be sent to the RoC within 30 days of receiving
          the solutions.`,
        icon: true,
      },
      {
        head: `E-Form INC – 27`,
        points: `The application needed to change a private limited
          company into a public limited corporation is known as
          E-Form INC-27. Within 15 days of the extraordinary
          general meeting's (EGM) resolutions being adopted,
          this form may be lodged with the RoC.`,
        icon: true,
      },
      {
        head: `Documents for filing of the form with RoC`,
        points: `EGM minutes, or minutes of the extraordinary general
          meeting.`,
        icon: true,
      },
      {
        points: `Copy of the MOA and the new AOA.`,
        icon: true,
      },
      {
        points: `For E-Form MGT-14, notice of the extraordinary general
          meeting, or EGM, with an explanation is given in
          accordance with Section 102 of the Act.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ConPvtToPubImages = [
    '/imgs/registration/iec/iec.png',
    '/imgs/business/form-filing.png',
    '/imgs/business/responsible.png',
  ];

  /*  Slider Content Component Data */
  const ConPvtToPubSCData = {
    id: 'benefits',
    background: [],
    mt_div: 'mt-3',
    header: `Public limited company benefits`,
    content: [
      {
        points: `Company with the highest potential for investment.`,
        icon: true,
      },
      {
        points: `Preferred industry for investors is business.`,
        icon: true,
      },
      {
        points: `Easy to obtain loans and finances.`,
        icon: true,
      },
      {
        points: `Continuous existence.`,
        icon: true,
      },
      {
        points: `Property owner.`,
        icon: true,
      },
      {
        points: `Easy share transfers.`,
        icon: true,
      },
    ],
    images: ConPvtToPubImages,
  };

  /*  Content Img Component Data */
  const ConPvtToPubCiData = {
    id: 'documents',
    background: 'main-content',
    mt_div: '',
    header: `Documentation needed to change a private limited
      company into a public limited`,
    paragraph: ``,
    points: [
      {
        content: `Copy of each director's voter ID or Aadhar card or PAN
          card.`,
        icon: true,
      },
      {
        content: `Director portraits the size of a passport.`,
        icon: true,
      },
      {
        content: `The lease for the designated business location.`,
        icon: true,
      },
      {
        content: `Water or electricity bill for the registered business
          location.`,
        icon: true,
      },
      {
        content: `The copy of the property documents is necessary if the
          office is located in its own location.`,
        icon: true,
      },
      {
        content: `The landlord's NOC, or No Objection Certificate.`,
        icon: true,
      },
    ],
    image: '/imgs/assited/statutory-compliance.png',
    alt_tag:
      'Documents required for the conversion of private to public limited',
  };

  var FAQ_data = [
    {
      header: 'Who is in charge of running a public limited company?',
      body: [
        {
          content: `A public limited company's shareholders are actually its owners,
          but they vote for and choose a board of directors who act on their
          behalf to manage and control the company.`,
          icon: false,
        },
      ],
    },

    {
      header: 'When should a corporation change its MOA and AOA?',
      body: [
        {
          content: `The corporation can prepare the MoA and AoA once the ROC has
          approved the name.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How long does the conversion procedure take in total?',
      body: [
        {
          content: `Within 20 to 25 working days, you can change your private limited
          company to a public one, but professional advice is recommended.
          The delivery of required documents has a significant impact on the
          conversion process' turnaround time.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Whether the Public Limited Company needs to re-register for GST?',
      body: [
        {
          content: `No, a name change application cannot be made for company records.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is name adjustment required during conversion?',
      body: [
        {
          content: `Yes, following conversion, the suffix "Private Limited" needs to
          be changed to "Limited."`,
          icon: false,
        },
      ],
    },
    {
      header: ' Whether alteration of name is mandatory while conversion? ',
      body: [
        {
          content: `Yes, the suffix “Private Limited” will be replaced with “Limited”."`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' How do you concern payment to yourself in a limited liability company? ',
      body: [
        {
          content: `There are limited ways, such as- A director’s salary, Issuing dividend payments from available profits, 
          Taking money out of a limited company, as a director’s loan, claiming expenses for business-related items`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the obligations of a Public Limited Company? ',
      body: [
        {
          content: `Subsequently the Public limited company deals with the public’s money, it requires taking 
          procedures which increase the statutory compliance on its part. The regulatory obligations are not 
          limited to the income tax but with ROC/MCA, SEBI, RBI, etc.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How much tax should a restricted liability company pay? ',
      body: [
        {
          content: `A limited liability company is a very tax-efficient business structure because limited companies 
          pay corporation tax on their profits, of a flat rate of 19%.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Conversion of Pvt Ltd and a Public Limited Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ConPvtToPubSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ConPvtToPubAboutData} />

          <Counter />

          <ScrollNav scroll_data={ConPvtToPubScrollId} />

          <ImgContent item={ConPvtToPubIcData} />

          <ContentForm CFSection_data={ConPvtToPubCfData} />

          <ContentSlider CsSection_data={ConPvtToPubSCData} />

          <ContentImg CISection_data={ConPvtToPubCiData} />

          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
